import cls from 'classnames';
import React from 'react';
import { Avatar, type AvatarProps } from 'antd';
import { useVipLevel } from '@/hooks';

import styles from './index.module.scss';

export const UserAvatar: React.FC<AvatarProps> = ({
  className,
  children,
  ...extraProps
}) => {
  const { vipLevel } = useVipLevel();

  return (
    <div className={cls(styles.wrap, className)}>
      <Avatar {...extraProps}>{children}</Avatar>
      {vipLevel.vip_icon && (
        <img className={styles.icon} src={vipLevel.vip_icon} />
      )}
    </div>
  );
};
