import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getVipLevelList } from '@/apis';
import { useUserInfo } from '@/stores';
import type { InferArrayValue } from '@/types/infer';

export function useVipLevel() {
  const { userInfo } = useUserInfo();
  const { data = [] } = useQuery([getVipLevelList.name], () =>
    getVipLevelList({}).then((res) => res.data?.list ?? []),
  );

  const vipLevels = useMemo(
    () =>
      Array.from(data).sort((first, next) => first.vip_exp! - next.vip_exp!),
    [data],
  );

  type LevelType = InferArrayValue<typeof data>;

  const { vipLevel, nextVipLevel } = useMemo<{
    vipLevel: LevelType;
    nextVipLevel: LevelType;
  }>(() => {
    const vipLevelIndex = vipLevels.findIndex(
      (conf) => conf.level === userInfo?.vip_level,
    );

    return {
      vipLevel: vipLevels[vipLevelIndex] ?? {
        id: -1,
        level: 'VIP',
        cash_back_diamond: 1,
        vip_exp: 0,
      },
      nextVipLevel: vipLevels[vipLevelIndex + 1],
    };
  }, [vipLevels, userInfo?.vip_level]);

  return {
    vipLevel,
    vipLevels,
    nextVipLevel,
  };
}
