import cls from 'classnames';
import React from 'react';
import { Modal, Button } from 'antd';
import type { ModalProps } from 'antd';

import titleBg from '@/assets/images/buy-result-title.png';

import styles from './index.module.scss';

export const HintModal: React.FC<ModalProps> = ({
  title,
  children,
  okText,
  okButtonProps,
  cancelText,
  cancelButtonProps,
  onOk,
  onCancel,
  ...extraProps
}) => {
  return (
    <Modal
      {...extraProps}
      wrapClassName={styles.wrap}
      title={
        <div className={styles.title}>
          <img
            className={cls('icon-center', styles.titleWrap)}
            src={titleBg}
          ></img>
          <span className={styles.titleText}>{title}</span>
        </div>
      }
      footer={
        <>
          {onCancel && (
            <Button
              size="large"
              onClick={(event) => {
                onCancel?.(event);
              }}
              {...cancelButtonProps}
            >
              {cancelText ?? 'No'}
            </Button>
          )}
          <Button
            size="large"
            type="primary"
            onClick={(event) => {
              onOk?.(event);
            }}
            {...okButtonProps}
          >
            {okText ?? 'OK'}
          </Button>
        </>
      }
      closable={false}
    >
      <div className={styles.content}>{children}</div>
    </Modal>
  );
};
