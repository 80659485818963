import cls from 'classnames';
import React, { useState } from 'react';
import { Button } from 'antd';
import SvgIcon from '@ant-design/icons';
import { ReactComponent as VipSvg } from '@/assets/svg/vip1.svg';
import { HintModal } from '@/components/HintModal';
import { useUserInfo } from '@/stores';
import { useKVConfig, useVipLevel } from '@/hooks';

import styles from './index.module.scss';

export interface VipLevelProps {
  style?: React.CSSProperties;
  showExp?: boolean;
}

export const VipLevel: React.FC<VipLevelProps> = ({ style, showExp }) => {
  const { userInfo } = useUserInfo();
  const [showDescModal, setShowDescModal] = useState(false);
  const { value: desc = '' } = useKVConfig('CUSTOM_PAGE_vip-level');
  const { vipLevel, nextVipLevel } = useVipLevel();

  if (!userInfo || !vipLevel) return null;

  return (
    <>
      <Button
        className={styles.vipInfo}
        style={style}
        type="text"
        onClick={() => setShowDescModal(true)}
      >
        <div className={styles.vipIcon}>
          <SvgIcon
            className={cls('icon-center', styles.vipSvg)}
            component={VipSvg}
            width={16}
            height={16}
          />
          <span className={styles.vipTitle}>{vipLevel?.level}</span>
        </div>
        {showExp && (
          <span>
            ({userInfo.vip_exp}/{nextVipLevel?.vip_exp ?? 'Max'})
          </span>
        )}
      </Button>
      <HintModal
        title="VIP Details"
        width={732}
        open={showDescModal}
        okText={'Ok'}
        onOk={() => setShowDescModal(false)}
        zIndex={1050}
      >
        <div dangerouslySetInnerHTML={{ __html: desc }}></div>
      </HintModal>
    </>
  );
};
