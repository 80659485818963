import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popover, Spin } from 'antd';
import { useUserInfo, useMoneyInfo, useCarts } from '@/stores';
import styles from './index.module.scss';
import Icon from '@/components/Icon';
import { useQuery } from '@tanstack/react-query';
import HeaderCart from '../HeaderCart';
import { throttle } from 'lodash';
import { VipLevel } from '@/components/VipLevel';
import { UserAvatar } from '@/components/UserAvatar';
import SvgIcon from '@ant-design/icons';
import { ReactComponent as VipSvg } from '@/assets/svg/vip.svg';
import { useVipLevel } from '@/hooks';

const HeaderUserInfo: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo, logout } = useUserInfo();
  const { moneyInfo, getMoney } = useMoneyInfo();
  const { vipLevel } = useVipLevel();
  const { getCartList, open, setOpen } = useCarts();
  // 先拿一次
  useQuery([getMoney.name], getMoney, {
    enabled: !!userInfo,
  });
  useQuery([getCartList.name], getCartList, {
    enabled: !!userInfo,
  });
  const fleshCart = useCallback(
    throttle(getCartList, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const openCart = (visible: boolean) => {
    setOpen(visible);
    if (!!visible) {
      fleshCart();
    }
  };
  const refleshCoin = useCallback(
    throttle(getMoney, 1500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const gotoWhere = (path: string) => navigate(path);
  const userContent = (
    <div className={styles.wrap}>
      {userInfo ? (
        <>
          <UserAvatar
            className={styles.avatar}
            src={userInfo.icon}
            alt={userInfo.nickname ?? userInfo.username}
            icon={<Icon id="#hxicon-user" className={styles.avatarUser} />}
            crossOrigin="anonymous"
            onClick={() => gotoWhere('/my')}
          ></UserAvatar>
          <p className={styles.name}>
            {userInfo.nickname ?? userInfo.username}
            <VipLevel showExp />
          </p>
          {!!moneyInfo && (
            <div className={styles.gold}>
              <span
                className={styles.goldItem}
                onClick={() => gotoWhere('/my/coin')}
              >
                <Icon id="#hxicon-gold" className={styles.goldIcon} />
                {moneyInfo.gold}
              </span>
              <span
                className={styles.goldItem}
                onClick={() => gotoWhere('/my/diamond')}
              >
                <Icon id="#hxicon-diamond" className={styles.goldIcon} />
                {moneyInfo.diamond}
              </span>
            </div>
          )}
          <div className={styles.operateItem} onClick={() => gotoWhere('/my')}>
            <Icon id="#hxicon-my" className={styles.operateIcon} />
            <span className={styles.operateName}>Personal center</span>
          </div>
          <div className={styles.operateItem} onClick={() => logout()}>
            <Icon id="#hxicon-logout" className={styles.logoutIcon} />
            <span className={styles.operateName}>Logout</span>
          </div>
        </>
      ) : (
        <Spin className="center-loading" />
      )}
    </div>
  );

  return userInfo ? (
    <>
      <Popover
        placement="bottomRight"
        overlayClassName={styles.cartPopover}
        content={<HeaderCart />}
        mouseEnterDelay={0.2}
        open={open}
        onOpenChange={openCart}
      >
        <span className={styles.iconWrap}>
          <Icon id="#hxicon-cart" className={styles.avatarIcon} />
        </span>
      </Popover>
      <Popover
        content={userContent}
        mouseEnterDelay={0.2}
        placement="bottomRight"
        onOpenChange={(visable) => !!visable && refleshCoin()}
      >
        <span className={styles.userWrap}>
          <Icon id="#hxicon-user" className={styles.avatarIcon} />
          {vipLevel && (
            <SvgIcon className={styles.vipIcon} component={VipSvg} />
          )}
        </span>
      </Popover>
    </>
  ) : (
    <Button type="primary" className={styles.btn} onClick={logout}>
      Sign In
    </Button>
  );
};

export default memo(HeaderUserInfo);
